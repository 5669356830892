import React from 'react';

const SquareWithNumber = ({number, index, onClick}) => {
    return number === 0 ?
        <div
            style={{
                width: '38px',
                height: '38px',
                border: 'none',
                display: 'flex',
            }}
        >
            {number === 0 ? "  " : number}
        </div>
        :
        <div onClick={onClick}
             style={{
                 width: '36px',
                 height: '36px',
                 border: '1px solid white',
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
                 fontSize: '24px',
                 fontWeight: 'bold',
                 backgroundColor: number % 2 === 0 ? "#113071" : "#11716c",
                 borderRadius: '10px',
                 // padding: "1px"
             }}
        >
            {number}
        </div>;
};

export default SquareWithNumber;
