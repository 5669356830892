import React, {useState} from "react";
import SquareWithNumber from "./SingleSquare";
import styles from '../App.css'

const Desk16Squares = () => {
    const initDeck = () => Array.from(Array(16)).map((value, index) => (index + 1) % 16);
    const [deck, setDeck] = useState(initDeck());

    function simpleSwap(zero, j) {
        deck[zero] = deck[j];
        deck[j] = 0;
        return j
    }

    function swap(index) {


        [index - 1, index + 1, index + 4, index - 4]
            .filter(idx => idx >= 0 && idx < 16 && Math.abs(index % 4 - idx % 4) < 2 && deck[idx] === 0)
            .forEach((zero) => {
                simpleSwap(zero, index);
                setDeck([...deck]);
            });
    }

    function shuffle(N) {
        let zero = deck.findIndex(value => value === 0);
        for (let i = 0; i < N; i++) {
            const randomNumber = Math.floor(Math.random() * 4); // Generates random integer from 0 to 3
            const shift = [-1, 1, -4, 4][randomNumber];
            [shift]
                .map(value => zero + value)
                .filter(idx => idx >= 0 && idx < 16 && Math.abs(zero % 4 - idx % 4) < 2)
                .forEach((idx) => {
                    zero = simpleSwap(zero, idx);
                    setDeck([...deck]);
                });
        }
    }


    return (
        <div>
            <div style={{
                display: "inline-grid",
                gridTemplateColumns: "40px 40px 40px 40px",
                gridTemplateRows: "40px 40px 40px 40px",
                padding: '2px',
                outline: '2px solid cyan',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: "radial-gradient(blue, white)",
                borderRadius: '10px',
            }}>
                {deck.map((num, index) => (<SquareWithNumber number={num} onClick={() => swap(index)}/>))}
            </div>
            <span/>

            <div style={{
                padding:"10px",
                display: "grid",
                gridTemplateColumns: "70px 20px 70px",
                gridTemplateRows: "20px"
            }}>
                <button onClick={() => setDeck(initDeck())}>
                    Reset
                </button>
                <span/>
                <button onClick={() => shuffle(100)}>
                    Shuffle
                </button>
            </div>
        </div>
    );
}
export {Desk16Squares} ;