import logo from './logo.svg';
import './App.css';
import SingleSquare from "./components/SingleSquare";
import {Desk16Squares, NumbersRow} from "./components/Desk16Squares";

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <Desk16Squares/>
      </header>
    </div>
  );
}

export default App;
